/* src/portfolio.css */
.portfolio {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .portfolio h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .project {
    width: 100%;
    height: 45em;
  }
  
  .project-iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  
  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* .slick-dots {
    bottom: -60px;
  } */
  
  .slick-prev:before, .slick-next:before {
    color: black;
  }